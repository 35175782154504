import { useParams } from 'react-router-dom';
import { Container, Paper } from '@mui/material';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { AssignmentForm } from '../../components/AssignmentForm';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';
import { useAssignment } from '../../api/useAssignment';

// TODO: UI Improve - Let's add ASSIGN if standard type, NEXT if template type?
export function AssignmentEditPage() {
  const { assignmentId } = useParams();

  const { data: assignment, isLoading, isError, error } = useAssignment(assignmentId);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb assignmentId={assignmentId} assignmentTitle={assignment.title} />
      <Paper sx={{ padding: 2 }}>
        <AssignmentForm assignment={assignment} />
      </Paper>
    </Container>
  );
}
