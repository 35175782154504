import { Button, CircularProgress, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { usePromptsConfigurationUpdateMutation } from './api/usePromptsConfigurationUpdateMutation';
import { VariablesInfo } from './VariablesInfo';

export const WritingPrompts = ({ promptsConfiguration }) => {
  const { register, handleSubmit } = useForm();
  const mutation = usePromptsConfigurationUpdateMutation();
  const saving = mutation.isLoading;

  const onSubmit = (data) => {
    mutation.mutate({ data, promptsConfigurationId: promptsConfiguration.id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VariablesInfo />
      <TextField
        label="Writing Assistant Instructions"
        helperText="This overrides the default assistant instructions set via openAI dasboard. We can think of it as a system message."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.writingAssistantInstructions}
        fullWidth
        sx={{ my: 2 }}
        {...register(`writingAssistantInstructions`)}
      />
      <TextField
        label={`'What do you think?' OUTLINE & WRITING prompt`}
        helperText="This prompt is applicable to all types of assignments and provides progress feedback, whether the text is present or not."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.whatDoYouThinkOutlinePrompt}
        fullWidth
        sx={{ my: 2 }}
        {...register(`whatDoYouThinkOutlinePrompt`)}
      />
      <TextField
        label={`'What do you think?' WRITING ONLY prompt`}
        helperText="This prompt is applicable to all types of assignments and provides progress feedback, whether the text is present or not."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.whatDoYouThinkWritingPrompt}
        fullWidth
        sx={{ my: 2 }}
        {...register(`whatDoYouThinkWritingPrompt`)}
      />
      <TextField
        label={`'Write Something For Me' OUTLINE & WRITING Prompt`}
        helperText="Applicable to all types of assignments, however requires students to write at least 16 words in the main text to be used."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.writeSthForMeOutlinePrompt}
        fullWidth
        sx={{ my: 2 }}
        {...register(`writeSthForMeOutlinePrompt`)}
      />
      <TextField
        label={`'Write Something For Me' WRITING ONLY Prompt`}
        helperText="Applicable to all types of assignments, however requires students to write at least 16 words in the main text to be used."
        multiline
        minRows={1}
        defaultValue={promptsConfiguration.writeSthForMeWritingPrompt}
        fullWidth
        sx={{ my: 2 }}
        {...register(`writeSthForMeWritingPrompt`)}
      />

      <Button variant="contained" type="submit" disabled={saving}>
        {saving && <CircularProgress sx={{ marginRight: 2 }} size={20} />}
        Save
      </Button>
    </form>
  );
}
