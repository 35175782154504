import { getName } from 'emoji-dictionary';
import { capitalizeWords } from './string';

export const getEmojiName = (emoji) => {
  try {
    return `${capitalizeWords(getName(emoji), '_')} - View Profile`;
  } catch (error) {
    return 'View Profile';
  }
};
