import { Children, useState } from 'react';
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableHead,
  Typography,
  TableCell,
  styled,
  TableRow,
  tableCellClasses,
  ButtonGroup,
  Box,
  TableFooter,
  TablePagination,
} from '@mui/material';

function CustomTable({ children, caption, ariaLabel, variant, noData = false, pagination = false, defaultRowsPerPage = 10 }) {
  const [rowsPerPage, setRowsPerPage] = useState(pagination ? defaultRowsPerPage : null);
  const [page, setPage] = useState(0);
  // const [sortField, setSortField] = useState(null);
  // const [sortDirection, setSortDirection] = useState('asc');

  const childrenArray = Children.toArray(children);
  const tableHead = childrenArray.filter((child) => child.type === TableHead);
  const thCount = tableHead[0]?.props.children.props.children.length;
  const buttonGroup = childrenArray.filter((child) => child.type === ButtonGroup);
  const tableBody = childrenArray.filter((child) => child.type === TableBody);
  const rows = tableBody.flatMap((body) => Children.toArray(body.props.children));
  const visibleRows = rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleSort = (field) => {
  //   const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
  //   setSortField(field);
  //   setSortDirection(direction);

  //   bodyRows = tableBody.sort((a, b) => {
  //     if (a.props[field] < b.props[field]) {
  //       return direction === 'asc' ? -1 : 1;
  //     }
  //     if (a.props[field] > b.props[field]) {
  //       return direction === 'asc' ? 1 : -1;
  //     }
  //     return 0;
  //   });
  // };

  return (
    <TableContainer component={Paper} variant={variant} sx={{px: 1.5}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 1.5 }}>
        {caption && <Typography variant="h4">{caption}</Typography>}
        {buttonGroup.length > 0 && <ButtonGroup>{buttonGroup}</ButtonGroup>}
      </Box>

      {noData ? (
        <Typography sx={{ paddingLeft: 2, paddingBottom: 1 }}>No Data</Typography>
      ) : (
        <Table aria-label={ariaLabel} size="small">
          {tableHead}
          {visibleRows}
          {pagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={thCount || 3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      )}
    </TableContainer>
  );
}
export { CustomTable as Table };

export const Tr = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': { backgroundColor: theme.palette.action.hover },
  '&:last-child td, &:last-child th': { border: 0 }, // hide last border
}));

export const Th = styled(TableCell)(({ theme, align = 'center', width }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.black,
    textAlign: align,
    paddingLeft: '5px',
    paddingRight: '5px',
    width,
  },
}));

export function Td({ children, align = 'center', width, style }) {
  return (
    <TableCell align={align} sx={{ paddingY: 0.2, paddingX: 0.3, width }} style={style}>
      {children}
    </TableCell>
  );
}
