import { Container, Typography, Box, Stack, Grid, Paper, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { GoogleLoginButton } from '../home/GoogleLoginButton';
import { GOOGLE_SIGN_UP_URL } from '../../config';
import { commonLoginWindowStyle } from '../home/Page';
// import { EmailSignUp } from './EmailSignUp';

// eslint-disable-next-line react/function-component-definition
export default function SignUpPage() {
  const signUpAsTeacher = () => window.open(`${GOOGLE_SIGN_UP_URL}/teacher`, '_self');
  const signUpAsStudent = () => window.open(`${GOOGLE_SIGN_UP_URL}/student`, '_self');

  return (
    <Container>
      <Typography
        variant="h4"
        component="h2"
        color="text.main"
        textAlign="center"
        sx={{ margin: 3, color: 'grey.600' }}
      >
        Sign Up to WordPath
      </Typography>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={6}>
          <Paper elevation={3} style={{ ...commonLoginWindowStyle }}>
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
              <Typography variant="h6">With Google</Typography>
              <GoogleLoginButton label="as Teacher" onClick={signUpAsTeacher} />
              <GoogleLoginButton label="as Student" onClick={signUpAsStudent} />
            </Stack>
            {/* <Divider light style={{ margin: '30px 0' }} />
            <EmailSignUp /> */}
            <Divider light style={{ margin: '30px 0' }} />
            <Link to="/">Return to the homepage</Link>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
