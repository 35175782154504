import { useState } from 'react';
import { ButtonGroup, FormControlLabel, Switch, TableBody, TableHead } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { Assignment as AssignmentIcon } from '@mui/icons-material';
import { ROUTES } from '../../../routes';
import { ButtonWithTooltip } from '../../../components/layout/ButtonWithTooltip';
import { UserAvatar } from '../../../components/Avatar';
import { copyToClipboard } from '../../../utils/clipboard';
import { Table, Td, Th, Tr } from '../../../components/layout/Table';
import { IconButtonWithTooltip } from '../../../components/layout/IconButtonWithTooltip';
import { UserEmoji } from '../../../components/Emoji';
import { getEmojiName } from '../../../helpers/emoji';
import { UserAssignmentStatus } from '../../../helpers/enums';
// import { SynchronizeStudents } from '../components/google/SynchronizeStudents';

export function StudentsList({ course, courseOwner }) {
  const [showAccessCodes, setShowAccessCodes] = useState(false);
  const maxNameChars = 20;
  const showAccessCodesSwitch = !course.externalId && course.courseUsers.length > 0; // QUESTION: Show access code for edlink users?

  const switchAccessCodesHandler = (event) => {
    setShowAccessCodes(event.target.checked);
  };

  const getFillColor = (status) => {
    switch (status) {
      case UserAssignmentStatus.CREATED:
        return 'gray';
      case UserAssignmentStatus.STARTED:
        return 'blue';
      case UserAssignmentStatus.SUBMITTED:
        return 'green';
      default:
        return 'black';
    }
  };

  // Consider https://mui.com/material-ui/react-table/#collapsible-table for details about assignments

  return (
    <Table caption="Students" noData={course.courseUsers.length === 0}>
      <TableHead>
        <Tr>
          <Th width="30px">#</Th>
          <Th align="left" width="230px">
            Name
          </Th>
          {showAccessCodes && (
            <Th width="160px" align="left">
              Access Code
            </Th>
          )}
          <Th align="left">Assignments</Th>
        </Tr>
      </TableHead>
      <ButtonGroup>
        {showAccessCodesSwitch && (
          <FormControlLabel
            control={
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={showAccessCodes}
                onChange={switchAccessCodesHandler}
              />
            }
            label="Show Access Codes"
          />
        )}
      </ButtonGroup>
      <TableBody>
        {course.courseUsers.map((courseUser, index) => {
          const { user: student, emoji } = courseUser;
          return (
            <Tr key={student.id}>
              <Td>{index + 1}</Td>
              <Td align="left">
                <ButtonWithTooltip
                  label={
                    student.name.length > maxNameChars ? `${student.name.slice(0, maxNameChars)}...` : student.name
                  }
                  tooltip={getEmojiName(emoji)}
                  to={ROUTES.showStudent(course.id, student.id)}
                  icon={emoji ? <UserEmoji emoji={emoji} /> : <UserAvatar alt={student.name} src={student.avatarUrl} />}
                />
              </Td>
              {showAccessCodes && (
                <Td align="left">
                  {(() => {
                    const studentCode = `${courseOwner.code}-${course.code}-${courseUser.code}`;
                    return (
                      <ButtonWithTooltip
                        label={studentCode}
                        key={studentCode}
                        tooltip="Click to copy the access code to clipboard"
                        onClick={() => copyToClipboard(studentCode)}
                        icon={<ContentCopy />}
                      />
                    );
                  })()}
                </Td>
              )}
              <Td align="left">
                {student.userAssignments.map((userAssignment) => (
                  <>
                    {`${userAssignment.assignment.title.substring(0, 3)}...`}
                    <IconButtonWithTooltip
                      tooltipPlacement="top"
                      key={userAssignment.id}
                      icon={
                        <AssignmentIcon key={userAssignment.id} style={{ fill: getFillColor(userAssignment.status) }} />
                      }
                      tooltip={`${userAssignment.assignment.title} - ${userAssignment.status}`}
                      to={ROUTES.showAssignmentSubmission(userAssignment.assignmentId, student.id)}
                    />
                  </>
                ))}
              </Td>
            </Tr>
          );
        })}
      </TableBody>
    </Table>
  );
}
