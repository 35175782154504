import { Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src="/error.jpg" alt="Error" style={{ marginBottom: '20px', marginTop: '20px' }} />
        <Typography variant="body1" align="center" marginBottom={2}>
          Please try again later or <a href="mailto:wordpath@u2i.com">contact us</a> if the problem persists.
        </Typography>
        <Link to="/">Return to the homepage</Link>
      </Box>
    </Container>
  );
}
