import { Box } from '@mui/material';
import { Check as CheckIcon, Remove as RemoveIcon } from '@mui/icons-material';

export function BooleanValue({ value, showLabel = false }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {value ? (
        <>
          <CheckIcon style={{ color: 'blue' }} /> {showLabel && 'Yes'}
        </>
      ) : (
        <>
          <RemoveIcon style={{ color: 'lightgrey' }} /> {showLabel && 'No'}
        </>
      )}
    </Box>
  );
}
