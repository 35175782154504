import React, { useContext, useState } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { useWritingContext } from '../../../../hooks/useWritingContext';
import { UserContext } from '../../../../contexts/UserContext';
import { Stage2Writing } from './components/Stage2Writing';
import { Stage1Outline } from './components/Stage1Outline';
import WritingAssignment from './components/WritingAssignment';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';
import { AssignmentInstructions } from '../../../../components/AssignmentInstructions';
import { useTemplate } from '../../../templates/api/useTemplate';
import { Stage3Review } from './components/Stage3Review';
import { Stage4Complete } from './components/Stage4Complete';
import { isOutlineAndWritingAssignment } from '../../../../helpers/assignment';
import { createOutlineTree, getOutlineStructure } from '../../../../helpers/template';
import { StepsDataContextProvider } from '../../../../contexts/StepsDataContext';
import { STEPS } from '../../../../helpers/constants';
import NotFoundPage from '../../../404/Page';
// import { getAnalysis, submitAssignment } from '../../../student/editor/api';
// import { ROUTES } from '../../../../routes';

export function AssignmentWritePage() {
  const user = useContext(UserContext); // TODO: Would  be great to see class, grade here
  const { assignmentId } = useParams();
  // const navigate = useNavigate();

  // TODO: add outline data?
  const [submissionData, setSubmissionData] = useState({ writingText: '', writingStats: {}, assignmentId, user });

  const {
    data: writingData, // TODO: Rename to WritingContext?
    isError: isWritingDataError,
    isLoading: isWritingDataLoading,
    error: writingDataError,
  } = useWritingContext({ assignmentId, userId: user.id });

  const {
    data: template,
    isTemplateLoading,
    isTemplateError,
    templateError,
  } = useTemplate(writingData?.assignment.templateId);

  if (isWritingDataLoading || isTemplateLoading) {
    return <LoadingIndicator />;
  }

  if (isWritingDataError || isTemplateError) {
    return <LoadingError error={writingDataError || templateError} />;
  }

  // const onFinish = async (text, writingStats) => {
  //   const isCreated = await submitAssignment(assignmentId, text, writingStats);

  //   if (isCreated) {
  //     getAnalysis(text, assignmentId)
  //       // .then((data) => {
  //       //   console.log(data);
  //       // })
  //       .catch((error) => {
  //         // eslint-disable-next-line no-console
  //         console.error(error);
  //       });

  //     enqueueSnackbar('Your writing assignment has been submitted!', { variant: 'success' });
  //     if (user.isTeacher) {
  //       navigate(ROUTES.showAssignment(assignmentId));
  //     } else {
  //       navigate(ROUTES.STUDENT_DASHBOARD);
  //     }
  //   }
  // };

  // TODO: Without it I see "Cannot destructure property 'templateComponents' of 'template' as it is undefined."
  // though it should be handled by the checks above
  // What's the proper way of fetchign two dependand requests?
  // Ideally it could be only one with all data

  const steps = writingData?.steps.reduce((acc, item) => {
    acc[item.id] = item.value;
    return acc;
  }, {});
  const { [STEPS.WRITING_TEXT]: writingText, ...outlineSteps } = steps;
  const studentData = writingData.student;

  let templateComponents = [];
  let templeteOutlineTree = {};
  // let outlineText = '';
  let outlineStructure = '';
  const useOutline = isOutlineAndWritingAssignment(writingData.assignment.type);

  if (useOutline) {
    if (template) {
      ({ templateComponents } = template);
      templeteOutlineTree = createOutlineTree(templateComponents);
      outlineStructure = useOutline ? getOutlineStructure(templeteOutlineTree) : '';
      // TODO: to sie zmienia i nie aktualizuje tego przez stan lub przez context, ciagle jest uzywany stan poczatkowy
      // outlineText = useOutline ? getOutlineText(templeteOutlineTree, outlineSteps) : '';
    } else {
      return (
        <NotFoundPage
          error="Template configuration error"
          assignmentId={assignmentId}
          assignmentTitle={writingData.assignment.title}
          userId={user.id}
          username={user.name}
        />
      );
    }
  }

  const grade = studentData.grade || writingData.assignment.grade || 'N/A';

  return (
    <Container>
      <Breadcrumb
        userId={user.id}
        username={user.name}
        assignmentId={writingData.assignment.id}
        assignmentTitle={writingData.assignment.title}
      />
      <AssignmentInstructions assignment={writingData.assignment} />

      <StepsDataContextProvider steps={outlineSteps}>
        <WritingAssignment
          submisionData={submissionData}
          useOutline={useOutline}
          grade={grade}
          templeteOutlineTree={templeteOutlineTree} // TODO: Rename?
          outlineStructure={outlineStructure} // TODO: rename?
          assignmentStatus={writingData.userAssignment.status}
        >
          <Stage1Outline
            assignment={writingData.assignment}
            outlineSteps={outlineSteps}
            userAssignment={writingData.userAssignment}
            templateComponents={templateComponents}
            grade={grade}
            templeteOutlineTree={templeteOutlineTree}
            outlineStructure={outlineStructure}
            templateName={template?.name}
          />
          <Stage2Writing
            assignment={writingData.assignment}
            writingText={writingText}
            userAssignment={writingData.userAssignment}
            setSubmissionData={setSubmissionData}
            useOutline={useOutline}
            templeteOutlineTree={templeteOutlineTree}
            outlineStructure={outlineStructure}
            grade={grade}
          />
          <Stage3Review templeteOutlineTree={templeteOutlineTree} useOutline={useOutline} />
          <Stage4Complete
            assignmentTitle={writingData.assignment.title}
            assignmentText={writingText}
            generateImage={writingData.assignment.generateImage}
          />
        </WritingAssignment>
      </StepsDataContextProvider>
    </Container>
  );
}
