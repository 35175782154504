import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { useWritingContext } from '../../../../hooks/useWritingContext';
import { UserContext } from '../../../../contexts/UserContext';
import { LoadingError } from '../../../../components/LoadingError';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';
import { WritingStepPreview } from './WritingStepPreview';
import { AssignmentInstructions } from '../../../../components/AssignmentInstructions';
import { useTemplate } from '../../../templates/api/useTemplate';
import { isOutlineAndWritingAssignment } from '../../../../helpers/assignment';
import { STEPS } from '../../../../helpers/constants';
import NotFoundPage from '../../../404/Page';

export function AssignmentSubmissionPage() {
  const { assignmentId, studentId } = useParams();
  const sessionUser = useContext(UserContext);

  const {
    data: writingData, // TODO: Rename to WritingContext?
    isError: isWritingDataError,
    isLoading: isWritingDataLoading,
    error: writingDataError,
  } = useWritingContext({ assignmentId, userId: studentId }); // TODO: for teacher studentId, for student sessionUser.id

  const {
    data: template,
    isTemplateLoading,
    isTemplateError,
    templateError,
  } = useTemplate(writingData?.assignment.templateId);

  if (isWritingDataLoading || isTemplateLoading) {
    return <LoadingIndicator />;
  }

  if (isWritingDataError || isTemplateError) {
    return <LoadingError error={writingDataError || templateError} />;
  }

  // TODO: Deduplicate
  const steps = writingData?.steps.reduce((acc, item) => {
    acc[item.id] = item.value;
    return acc;
  }, {});
  const { [STEPS.WRITING_TEXT]: writingText, ...outlineSteps } = steps;

  let templateComponents = [];
  const useOutline = isOutlineAndWritingAssignment(writingData.assignment.type);

  if (useOutline) {
    if (template) {
      ({ templateComponents } = template);
    } else {
      return <NotFoundPage error="Template configuration error" />;
    }
  }

  return (
    <Container>
      {/* TODO: Consider changing breadcrumb for student (remove /submissions/username) */}
      <Breadcrumb
        userId={writingData.student.id}
        username={writingData.student.name}
        assignmentId={assignmentId}
        assignmentTitle={writingData.assignment.title}
      />

      <AssignmentInstructions assignment={writingData.assignment} />

      <WritingStepPreview
        sessionUser={sessionUser}
        outlineSteps={outlineSteps}
        writingText={writingText}
        userAssignment={writingData.userAssignment}
        templateComponents={templateComponents}
        useOutline={useOutline}
        studentId={writingData.student.id}
      />
    </Container>
  );
}
