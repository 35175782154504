import { createContext, useCallback, useContext, useState } from 'react';

import { RequireLoginModal } from '../components/RequireLoginModal';

// eslint-disable-next-line no-unused-vars
const RequiresLoginContext = createContext((requiresLogin) => {});

export const RequiresLoginContextProvider = ({ children }) => {
  const [loginRequired, setLoginRequired] = useState(false);
  const requireLogin = useCallback(() => {
    setLoginRequired(true);
  }, []);

  return (
    <RequiresLoginContext.Provider value={requireLogin}>
      {loginRequired && (
        <RequireLoginModal onComplete={() => setLoginRequired(false)} />
      )}
      {children}
    </RequiresLoginContext.Provider>
  );
}

export const useRequireLogin = () => useContext(RequiresLoginContext);
