import { Button, Container, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '../../components/layout/Breadcrumb';
import { AssignmentsList } from './components/AssignmentsList';
import { useAssignments } from './api/useAssignments';
import { useRequireLogin } from '../../contexts/RequiresLoginContext';
import { LoadingError } from '../../components/LoadingError';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ROUTES } from '../../routes';
import { AddButton } from '../../components/AddButton';

export function AssignmentsPage() {
  const { data: assignments, isLoading, isError, error } = useAssignments();
  const requireLogin = useRequireLogin();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError && error.response?.data?.googleSync) { // ?
    requireLogin();
    return null;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb />
      {assignments.length > 0 ? (
        <AssignmentsList assignments={assignments} />
      ) : (
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h5">
            You do not have any assignments yet
            <AddButton to={ROUTES.newClass()} />
          </Typography>
          <Button variant="contained" color="primary" component={Link} to={ROUTES.newAssignment()} sx={{ marginY: 3 }}>
            Create your first assignment
          </Button>
        </Paper>
      )}
      <AddButton path={ROUTES.ASSIGNMENTS_NEW} tooltip="Add New Assignment" />
    </Container>
  );
}
