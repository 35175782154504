import { Backdrop, Button, Container, Paper, Typography } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import SchoolIcon from '@mui/icons-material/School';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { LoadingError } from '../../components/LoadingError';
import { Breadcrumb } from '../../components/layout/Breadcrumb';
import { useCoursesList } from './api/useCoursesList';
import { ROUTES } from '../../routes';
import { UserContext } from '../../contexts/UserContext';
import { SpeedDialAddButton } from '../../components/SpeedDialAddButton';

export const ClassesIndexPage = () => {
  const { data: courses, isLoading, isError, error } = useCoursesList();
  const user = useContext(UserContext);
  const isGoogleAccount = user.externalSource === 'google';
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


  const speedDialActions = [
    { icon: <SchoolIcon />, name: 'Add a new class', route: ROUTES.CLASSES_NEW },
    isGoogleAccount && { icon: <SchoolIcon />, name: 'Import Google Classroom class', route: ROUTES.CLASSES_IMPORT },
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  if (courses.length > 0) {
    return <Navigate replace to={ROUTES.showClass(courses[0].id)} />;
  }



  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 2 }}>
        <Typography variant="h5">You do not have any classes yet</Typography>
        <Button variant="contained" color="primary" component={Link} to={ROUTES.newClass()} sx={{ marginY: 3 }}>
          Create your first class
        </Button>
        {isGoogleAccount && (
          <Button variant="contained" color="primary" component={Link} to={ROUTES.importClass()} sx={{ marginLeft: 3 }}>
            Import Google Classroom class
          </Button>
        )}
      </Paper>
      <SpeedDialAddButton actions={speedDialActions} onOpen={handleOpen} onClose={handleClose} editEcon={false} />
      <Backdrop open={open} onClick={handleClose} />
    </Container>
  );
}
