import { Typography, Breadcrumbs, Box } from '@mui/material';
import {
  useLocation,
  Link,
  // useParams
} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { GoBackButton } from '../GoBackButton';

export function Breadcrumb({
  userId,
  username,
  courseId,
  courseName,
  assignmentId,
  assignmentTitle,
  templateId,
  templateName,
}) {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  // const { courseId, assignmentId } = useParams();
  // console.log('PARAMS:', useParams());
  // console.log('PROPS', userId, username, courseId, courseName, assignmentId, assignmentTitle);
  // console.log(location, location.pathname);
  // console.log(pathnames);

  const breadcrumbNameMap = {
    '/profile': 'Your Profile',

    '/templates': 'Templates',
    '/templates/new': 'New Template',
    [`/templates/${templateId}`]: templateName,
    [`/templates/${templateId}/edit`]: 'Edit',

    '/assignments': 'Assignments',
    '/assignments/new': 'New Assignment',
    [`/assignments/${assignmentId}`]: assignmentTitle,
    [`/assignments/${assignmentId}/edit`]: 'Edit',
    [`/assignments/${assignmentId}/assign`]: 'Assign Students',
    [`/assignments/${assignmentId}/template`]: 'Select Template',
    [`/assignments/${assignmentId}/${userId}`]: username,
    [`/assignments/${assignmentId}/${userId}/write`]: `Let's Write!`,
    [`/assignments/${assignmentId}/submissions`]: 'Submissions',
    [`/assignments/${assignmentId}/submissions/${userId}`]: username, // TODO: Would be nice to add classname or make tooltip here?
    [`/assignments/${assignmentId}/analytics`]: 'Replay & Analysis',
    [`/assignments/${assignmentId}/analytics/${userId}`]: username,

    '/classes': 'Classes',
    '/classes/new': 'New Class',
    '/classes/import': 'Google Classroom import',
    [`/classes/${courseId}`]: courseName,
    [`/classes/${courseId}/students`]: 'Students',
    [`/classes/${courseId}/students/${userId}`]: username,
    '/admin': 'Admin',
    '/admin/users': 'Manage Users',
    [`/admin/users/${userId}`]: username,
    [`/admin/users/${userId}/edit`]: 'Edit',
    '/admin/users/new': 'Add New',
    '/admin/templates': 'Manage Prompts',
    '/admin/prompts-configurations': 'Manage Prompts',

    [`/student/${courseId}`]: 'Assignments',
    [`/student/assignments`]: 'Assignments',
    [`/student/assignments/${assignmentId}`]: assignmentTitle,
    [`/student/assignments/${assignmentId}/${userId}`]: 'My Submission',
    [`/student/assignments/${assignmentId}/write`]: `Let's Write!`,

    // TODO: /dashboard shared across many roles or separate per role?
  };

  const nonClickableItems = [
    '/admin',
    `/classes/${courseId}/students`,
    `/admin/users/${userId}`,
    `/templates/${templateId}`,
    `/assignments/${assignmentId}/${userId}`,
    `/assignments/${assignmentId}/submissions`,
    `/assignments/${assignmentId}/analytics`,
    '/student',
    '/student/assignments',
    `/student/assignments/${assignmentId}`,
    `/student/assignments/${assignmentId}/${userId}`,
    `/student/assignments/${assignmentId}`,
  ];

  const nonVisibleItems = ['/student'];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '15px',
        marginTop: '10px',
      }}
    >
      <Breadcrumbs
        sx={{ marginBottom: '15px', marginTop: '10px' }}
        separator={<NavigateNextIcon fontSize="medium" />}
        aria-label="breadcrumb"
      >
        <HomeIcon sx={{ mr: 0.5 }} fontSize="medium" />

        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const nonClickable = nonClickableItems.includes(to);
          // console.log('TO:', to);

          if (nonVisibleItems.includes(to)) {
            return null;
          }

          // TODO: Change color/style of clickable elements
          return last || nonClickable ? (
            <Typography color="text.primary" key={to} variant={nonClickable ? 'overline' : 'button'}>
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <Link component={Link} underline="hover" color="inherit" to={to} key={to}>
              <Typography color="text.primary" key={to} variant="overline">
                {breadcrumbNameMap[to]}
              </Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
      <GoBackButton />
    </Box>
  );
}
