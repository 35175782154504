import { useContext, useState } from 'react';
import { Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTemplate } from './api/useTemplate';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { LoadingError } from '../../components/LoadingError';
import { TemplateContext } from './TemplateProvider';
import { createOutlineTree } from '../../helpers/template';

export function TemplatePreview({ showDescriptions = true }) {
  const { selectedTemplateId } = useContext(TemplateContext);
  const { data: template, isLoading, isError, error } = useTemplate(selectedTemplateId);
  const [open, setOpen] = useState({});

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  const style = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    paddingTop: 6,
  };

  const outlineTree = createOutlineTree(template.templateComponents);

  const handleClick = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
  };

  return (
    <Paper style={style}>
      <Typography variant="h4" sx={{ textAlign: 'center', mt: 1.5 }}>
        {template.name}
      </Typography>
      <Box sx={{ padding: 1.5, paddingTop: 1 }}>
        {outlineTree.length === 0 ? (
          <Typography variant="body1" color="textSecondary" align="center">
            No sections/components defined
          </Typography>
        ) : (
          outlineTree.map((step) => (
            <List key={step} dense sx={{ padding: 0, margin: 0 }}>
              <ListItem sx={{ paddingY: 0, paddingLeft: 1 }}>
                <ListItemText
                  primary={
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {step.name}
                    </Typography>
                  }
                />
              </ListItem>
              {step.components.length > 0 && <Divider />}
              {step.components.map((component) => (
                <List key={component} dense sx={{ paddingLeft: 3, paddingY: 0, margin: 0 }}>
                  {showDescriptions ? (
                    <ListItemText
                      primary={<Typography sx={{ fontWeight: 'bold' }}>{component.name}</Typography>}
                      sx={{ mb: 0 }}
                    />
                  ) : (
                    <ListItemButton onClick={() => handleClick(component.id)} sx={{ padding: 0 }}>
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 'bold' }}>{component.name}</Typography>}
                        sx={{ mb: 0 }}
                      />
                      {open[component.id] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  )}
                  <Collapse
                    in={open[component.id] || showDescriptions}
                    timeout="auto"
                    unmountOnExit
                    key={component}
                    sx={{ mb: 0.5 }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      {component.shortDescription}
                    </Typography>
                  </Collapse>
                </List>
              ))}
            </List>
          ))
        )}
      </Box>
    </Paper>
  );
}
