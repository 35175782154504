import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Box, Button, CircularProgress, Container, Grid, List, Paper, Typography } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Cancel';
import { captureException } from '@sentry/react';
import ShareIcon from '@mui/icons-material/Share';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';
import { useAssignment } from '../../api/useAssignment';
import TemplateListItem from './TemplateListItem';
import { useTemplates } from '../../../templates/api/useTemplates';
import { useAssignmentMutation } from '../../api/useAssignmentMutation';
import { ROUTES } from '../../../../routes';
import { TemplatePreview } from '../../../templates/TemplatePreview';
import { TemplateProvider } from '../../../templates/TemplateProvider';

export function AssignmentTemplatePage() {
  const { assignmentId } = useParams();
  const [templateId, setTemplateId] = useState();
  const [submitType, setSubmitType] = useState('save');
  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isErrorTemplates,
    error: errorTemplates,
  } = useTemplates();
  const navigate = useNavigate();

  const {
    data: assignment,
    isError: isErrorAssignment,
    isLoading: isLoadingAssignment,
    error: errorAssignment,
  } = useAssignment(assignmentId);

  const assignmentMutation = useAssignmentMutation(assignment?.id, {
    onSuccess: (result) => {
      navigate(
        submitType === 'save' ? ROUTES.showAssignment(result.assignment.id) : ROUTES.assignToAssignment(assignmentId)
      );
    },
    onError: (result) => {
      captureException(`Assignment error: ${result}`);
    },
  });

  if (isLoadingAssignment || isLoadingTemplates) {
    return <LoadingIndicator />;
  }

  if (isErrorAssignment || isErrorTemplates) {
    return <LoadingError error={errorAssignment || errorTemplates} />;
  }

  const handleSave = (e) => {
    e.preventDefault();
    setSubmitType(e.target.name);

    const updatedAssignment = { ...assignment, templateId };
    // TODO: Change to pass only templateId param we'd like to save, which is currently not possible due to AssignmentRequest
    assignmentMutation.mutate({ assignment: updatedAssignment });
  };

  const wordpathTemplates = templates ? templates.filter((template) => template.ownerId === null) : [];
  const userTemplates = templates ? templates.filter((template) => template.ownerId !== null) : [];

  return (
    <TemplateProvider defaultSelectedId={templates[0]?.id}>
      <Container>
        <Breadcrumb assignmentId={assignmentId} assignmentTitle={assignment.title} />
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Select Template for assignment: {assignment.title}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} paddingTop={4}>
              <Typography variant="h6" marginBottom={1} marginTop={3} align="center" sx={{ color: '#4352af' }}>
                My Outline Templates
              </Typography>
              <List>
                {userTemplates.map((template) => (
                  <TemplateListItem key={template.id} template={template} setTemplateId={setTemplateId} />
                ))}
                <Typography variant="h6" paddingTop={1} align="center" sx={{ color: '#4352af' }}>
                  WordPath Outline Templates
                </Typography>
                {wordpathTemplates.map((template) => (
                  <TemplateListItem key={template.id} template={template} setTemplateId={setTemplateId} />
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <TemplatePreview showDescriptions={false} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingY: 2, gap: 2 }}>
            <Button
              disabled={assignmentMutation.isLoading}
              name="cancel"
              to={ROUTES.editAssignment(assignmentId)}
              startIcon={<CancelIcon />}
              component={Link}
            >
              Cancel
            </Button>
            <Button
              disabled={assignmentMutation.isLoading}
              name="save"
              type="submit"
              startIcon={<SaveAsIcon />}
              onClick={handleSave}
            >
              {assignmentMutation.isLoading ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : 'Save'}
            </Button>
            <Button
              disabled={assignmentMutation.isLoading}
              name="assign"
              type="submit"
              variant="contained"
              startIcon={<ShareIcon />}
              onClick={handleSave}
            >
              {assignmentMutation.isLoading ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : 'Save and Assign'}
            </Button>
          </Box>
        </Paper>
      </Container>
    </TemplateProvider>
  );
}
