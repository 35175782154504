import { Box } from '@mui/material';
import { InfoTooltip } from '../../../components/InfoTooltip';

export const VariablesInfo = ({ showOutlineVariables = false }) => (
    <Box>
      When changing the prompts below, you can use the following variables that will be replaced by the actual values:
      <ul>
        <li>$ASSIGNMENT_TITLE</li>
        <li>$ASSIGNMENT_INSTRUCTIONS</li>
        <li>
          $ASSIGNMENT_GRADE
          <InfoTooltip
            text={`This information is taken from a student's class configuration or their writing assignment's grade. It either returns "ungraded" or "[grade level] grade" string.`}
          />
        </li>
        <li>
          $OUTLINE_STRUCTURE
          <InfoTooltip text="Template structure (without descriptions)" />
        </li>
        <li>
          $OUTLINE_TEXT
          <InfoTooltip text="Template structure with user's input" />
        </li>
        {showOutlineVariables ? (
          <>
            <li>
              $TEMPLATE_NAME
              <InfoTooltip text="The name of the current template (Outline step only)" />
            </li>
            <li>
              $COMPONENT_NAME
              <InfoTooltip text="The name of the current component (Outline step only)" />
            </li>
            <li>
              $COMPONENT_PARAGRAPH
              <InfoTooltip text="The name of the corresponding paragraph for $COMPONENT_NAME (Outline step only)." />
            </li>
            <li>
              $COMPONENT_DESCRIPTION
              <InfoTooltip text="The description of the current component (Outline step only)" />
            </li>
            <li>
              $COMPONENT_WRITING
              <InfoTooltip text="The writing that the student that has entered for the current component (Outline step only)" />
            </li>
          </>
        ) : (
          <li>
            $WRITING_TEXT
            <InfoTooltip text="Writing assignment main text" />
          </li>
        )}
      </ul>{' '}
    </Box>
  );
