import { Box, Button, TableBody, TableHead, TableSortLabel } from '@mui/material';
import { Assignment as AssignmentIcon, Preview as PreviewIcon } from '@mui/icons-material';
import { useContext } from 'react';
import { visuallyHidden } from '@mui/utils';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import ReactGA from 'react-ga4';
import { ROUTES } from '../../../../routes';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { useCourseAssignments } from '../api/useCourseAssignments';
import { useSorting } from '../../../../hooks/useSorting';
import { Table, Td, Th, Tr } from '../../../../components/layout/Table';
import { IconButtonWithTooltip } from '../../../../components/layout/IconButtonWithTooltip';
import { UserContext } from '../../../../contexts/UserContext';
import { UserAssignmentStatus } from '../../../../helpers/enums';
import { getAssignmentStatus } from '../../../../helpers/assignment';

function SortableTh({ children, id, onSort, order, orderBy, align = 'center'}) {
  return (
    <Th align={align}>
      <TableSortLabel active={orderBy === id} direction={orderBy === id ? order : 'asc'} onClick={onSort(id)}>
        {children}
        {orderBy === 'title' ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    </Th>
  );
}

const sendSortingEvent = (property, order) => {
  ReactGA.event({
    category: 'student-dashboard',
    action: `sort-${property}-${order}`,
  });
};

export function AssignmentsList({ courseId }) {
  const { data: assignments, isLoading, isError, error } = useCourseAssignments(courseId);
  const { order, orderBy, createSortHandler, sortItems } = useSorting({
    defaultField: 'assignedAt',
    defaultOrder: 'desc',
    fallbackField: 'title',
    onSort: sendSortingEvent,
  });
  const sessionUser = useContext(UserContext);


  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Table ariaLabel="List of assignments in the class" caption="My Assignments" noData={assignments.length === 0}>
      <TableHead>
        <Tr>
          <SortableTh id="title" onSort={createSortHandler} order={order} orderBy={orderBy}>
            Title
          </SortableTh>
          <SortableTh id="dueDate" onSort={createSortHandler} order={order} orderBy={orderBy}>
            Due date
          </SortableTh>
          <SortableTh id="assignedAt" onSort={createSortHandler} order={order} orderBy={orderBy}>
            Assigned at
          </SortableTh>
          <SortableTh id="status" onSort={createSortHandler} order={order} orderBy={orderBy}>
            Status
          </SortableTh>
          <Th>Actions</Th>
        </Tr>
      </TableHead>
      <TableBody>
        {sortItems(assignments).map((assignment) => (
          <Tr key={assignment.id}>
            <Td>{assignment.title}</Td>
            <Td>{assignment.dueDate ? format(assignment.dueDate, 'P') : '-'}</Td>
            <Td>{assignment.assignedAt ? format(assignment.assignedAt, 'P') : '-'}</Td>
            <Td>{getAssignmentStatus(assignment.status)}</Td>
            <Td>
              {assignment.status !== UserAssignmentStatus.SUBMITTED ? (
                <Button
                  component={Link}
                  size="small"
                  startIcon={<AssignmentIcon />}
                  to={ROUTES.studentWrite(assignment.id)}
                >
                  Do assignment
                </Button>
              ) : (
                <IconButtonWithTooltip
                  tooltip="View My Submission"
                  icon={<PreviewIcon />}
                  to={ROUTES.showStudentSubmission(assignment.id, sessionUser.id)}
                />
              )}
            </Td>
          </Tr>
        ))}
      </TableBody>
    </Table>
  );
}
