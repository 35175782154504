import { Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src="/404.jpg" alt="Page not found" style={{ marginBottom: '20px' }} />
        <Typography variant="h5" sx={{ flexGrow: 1 }} marginBottom={2} align="center">
          Oops! The page you are looking for does not exist.
        </Typography>
        <Typography variant="body1" align="center">
          It seems that the page you are trying to reach is not available. Please check the URL or{' '}
          <Link to="/">return to the homepage</Link>.
        </Typography>
      </Box>
    </Container>
  );
}
