import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { isEmpty, omit } from 'ramda';

import { enqueueSnackbar } from 'notistack';
import { ROUTES } from '../../../routes';
import { useCourseMutation } from '../api/useCourseMutation';
import { GoogleCourseField } from './google/GoogleCourseField';
import { StudentsListField } from './StudentsListField';
import { DeleteCourseButton } from './DeleteCourseButton';
import { SynchronizeStudents } from './google/SynchronizeStudents';
// import { GradesMenuItems } from '../../../components/GradesMenuItems';
import { Grades } from '../../../helpers/enums';
import { capitalizeWords } from '../../../helpers/string';

const ERROR_MESSAGES = {
  name: 'You need to pick a name for your class',
  grade: 'You need to pick a grade level for your class',
};
const EMPTY_ERRORS = { name: '', grade: '' };

const getFormData = omit(['students-name']);
const getGradeKey = (value) => Object.keys(Grades).find((key) => Grades[key] === value);

// INFO: Here we just create standard, app native class or import from Google Classroom
// INFO: EdLink classes can only by created via EdLink data sync process
export const ClassForm = ({ course, googleCourses }) => {
  const navigate = useNavigate();
  const [selectedClassroom, setSelectedClassroom] = useState('');
  const [formValues, setFormValues] = useState(course || {});
  const [formErrors, setFormErrors] = useState(EMPTY_ERRORS);
  const [grade, setGrade] = useState(formValues.grade || getGradeKey(Grades.FIRST_GRADE));
  // TODO: We should prevent editing edlink classes here, still, in the end worth double checking both, id and source
  const isGoogleCourse = course?.externalId && course?.externalSource === 'google';
  const mutation = useCourseMutation({
    // INFO: inside we invalidate CLASSES_QUERY_KEY but it does not work when creating a new class
    onSuccess: (result) => {
      navigate(ROUTES.showClass(result.course.id));
      if (course) {
        enqueueSnackbar('Changes saved!', { variant: 'success' });
      }
    },
  });

  const validateForm = (values) => {
    const errors = { ...EMPTY_ERRORS };

    if (isEmpty(values.courseId)) {
      errors.courseId = ERROR_MESSAGES.courseId; // TODO: add courseId to ERROR_MESSAGES
    }

    if (isEmpty(values.name)) {
      errors.name = ERROR_MESSAGES.name;
    }

    if (isEmpty(values.grade)) {
      errors.grade = ERROR_MESSAGES.grade;
    }

    setFormErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const studentIds = formData.getAll('students-id');
    const students = formData
      .getAll('students-name')
      .filter(Boolean)
      .map((name, idx) => ({
        name,
        id: studentIds[idx] ? parseInt(studentIds[idx], 10) : undefined,
      }));
    const values = { ...getFormData(Object.fromEntries(formData)), students };

    if (values.googleCourseId) {
      values.externalId = values.googleCourseId;
      values.externalSource = 'google';
      delete values.googleCourseId;
    }

    if (validateForm(values)) {
      mutation.mutate({ data: values });
    }
  };

  const handleFieldChange = (e) => {
    setFormErrors((currentErrors) => {
      if (!isEmpty(currentErrors[e.target.name])) {
        return {
          ...currentErrors,
          [e.target.name]: '',
        };
      }

      return currentErrors;
    });
  };

  const handleGoogleClassroomChange = (values) => {
    setSelectedClassroom(values.externalId);
    setFormValues(values);
  };

  const handleGradeChange = (e) => {
    setGrade(e.target.value);
    handleFieldChange(e);
  };

  return (
    <Box
      key={selectedClassroom}
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {course && <input type="hidden" name="id" value={course.id} />}
      {(isGoogleCourse || googleCourses) && (
        <>
          <GoogleCourseField
            course={course}
            googleCourseId={formValues.googleCourseId}
            googleCourses={googleCourses}
            onChange={handleGoogleClassroomChange}
          />
          <Divider>
            <Typography
              color="text.secondary"
              sx={{
                fontSize: '0.75rem',
                lineHeight: 1,
                textTransform: 'uppercase',
              }}
            >
              {formValues.googleCourseId ? 'and fill in other details' : 'or create class manually'}
            </Typography>
          </Divider>
        </>
      )}
      <TextField
        defaultValue={formValues.name}
        error={!isEmpty(formErrors.name)}
        helperText={formErrors.name}
        fullWidth
        id="course-name"
        label="Class name"
        name="name"
        onChange={handleFieldChange}
        variant="filled"
      />
      <TextField
        defaultValue={formValues.description || ''}
        fullWidth
        id="course-description"
        label="Class description"
        name="description"
        onChange={handleFieldChange}
        variant="filled"
      />
      <TextField
        error={!isEmpty(formErrors.grade)}
        helperText={formErrors.grade}
        value={grade}
        fullWidth
        id="course-grade"
        label="Grade"
        name="grade"
        onChange={handleGradeChange}
        select
        variant="filled"
      >
        {/* <GradesMenuItems /> // TODO: use shared component */}
        {Object.entries(Grades).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value} - {capitalizeWords(key.replace(/_/g, ' '))}
          </MenuItem>
        ))}
      </TextField>
      <StudentsListField
        defaultValue={formValues.students || []}
        googleClass={Boolean(formValues.googleCourseId)}
        course={course}
        id="course-students"
        label="List of students"
        name="students"
      />
      <Box sx={{ display: 'flex', justifyContent: course ? 'space-between' : 'flex-end' }}>
        {course && <DeleteCourseButton courseId={course.id} />}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingY: 2,
            gap: 2,
          }}
        >
          <Button
            component={Link}
            to={course ? ROUTES.showClass(course.id) : ROUTES.CLASSES_INDEX}
            size="small"
            variant="outlined"
          >
            Cancel
          </Button>
          {isGoogleCourse && (
            <SynchronizeStudents courseId={course.id}>
              Synchronize students list with Google Classroom
            </SynchronizeStudents>
          )}
          <Button disabled={mutation.isLoading} type="submit" variant="contained" size="small">
            {mutation.isLoading && <CircularProgress size={15} sx={{ margin: 0.5 }} />}
            {!mutation.isLoading && (course ? 'Update class' : 'Create class')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
