import { UserAssignmentStatus } from "./enums";
import { capitalizeWords } from "./string";

export const AssignmentType = {
  WRITING: 'WRITING',
  OUTLINE_AND_WRITING: 'OUTLINE_AND_WRITING',
  QUIZ: 'QUIZ',
};

export const AssignmentTypeText = {
  [AssignmentType.WRITING]: 'Writing Assignment',
  [AssignmentType.OUTLINE_AND_WRITING]: 'Outline and Writing Assignment',
  [AssignmentType.QUIZ]: 'Quiz Assignment',
};

// export const isWritingAssignment = (type) => type === AssignmentType.WRITING;
export const isOutlineAndWritingAssignment = (type) => type === AssignmentType.OUTLINE_AND_WRITING;

export const getAssignmentStatus = (status) => {
  if (status === UserAssignmentStatus.CREATED || status === UserAssignmentStatus.PUBLISHED) {
    return 'Not Started';
  }

  return capitalizeWords(status.toLowerCase());
}
