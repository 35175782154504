import { useParams } from 'react-router-dom';
import { Container, Paper, Typography, Grid, Divider } from '@mui/material';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { useWritingContext } from '../../../../hooks/useWritingContext';
import { StepsAnalytics } from './components/StepsAnalytics';
import { LoadingError } from '../../../../components/LoadingError';
import { GwenStepContextProvider } from './contexts/GwenStepContext';
import { InfoTooltip } from '../../../../components/InfoTooltip';
import { WritingStats } from '../../components/WritingStats';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';
import { isOutlineAndWritingAssignment } from '../../../../helpers/assignment';
import { useTemplate } from '../../../templates/api/useTemplate';
import { TOOLTIPS } from '../../../../helpers/tooltips';
import { STEPS } from '../../../../helpers/constants';
import NotFoundPage from '../../../404/Page';

export function AssignmentAnalyticsPage() {
  const { assignmentId, studentId } = useParams();

  const {
    data: writingData, // TODO: Rename to WritingContext?
    isError: isWritingDataError,
    isLoading: isWritingDataLoading,
    error: writingDataError,
  } = useWritingContext({ assignmentId, userId: studentId });

  const {
    data: template,
    isTemplateLoading,
    isTemplateError,
    templateError,
  } = useTemplate(writingData?.assignment.templateId);

  if (isWritingDataLoading || isTemplateLoading) {
    return <LoadingIndicator />;
  }

  if (isWritingDataError || isTemplateError) {
    return <LoadingError error={writingDataError || templateError} />;
  }

  const steps = writingData?.steps.reduce((acc, item) => {
    acc[item.id] = item.value;
    return acc;
  }, {});
  // TODO: maybe we could use outlineSteps instead of asking api for each outline substep (via templateComponents in StepsAnalytics)
  // eslint-disable-next-line no-unused-vars
  const { [STEPS.WRITING_TEXT]: writingText, ...outlineSteps } = steps; // INFO: we have to us [] so JS know we'd like to use the value of the variable as the key, not "STEPS.WRITING_TEXT" string

  let templateComponents = [];
  const useOutline = isOutlineAndWritingAssignment(writingData.assignment.type);

  if (useOutline) {
    if (template) {
      ({ templateComponents } = template);
    } else {
      return <NotFoundPage error="Template configuration error" />;
    }
  }

  return (
    <Container>
      <Breadcrumb
        assignmentId={assignmentId}
        assignmentTitle={writingData.assignment.title}
        userId={writingData.student.id}
        username={writingData.student.name} /* TODO: It'd be nice to add class info or tooltip */
      />
      <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }} marginBottom={2} align="center">
          Replay & Analysis for <strong>{writingData.assignment.title}</strong> submitted by{' '}
          <strong>{writingData.student.name}</strong>
        </Typography>
        <Divider />
        <Grid container mt={3}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ flexGrow: 1 }} marginBottom={2}>
              Gwen&apos;s Analysis
              <InfoTooltip text={TOOLTIPS.GWENS_ANALYSIS} />
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {writingData.userAssignment.analysisPromptResult}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ flexGrow: 1 }} marginBottom={2}>
              Writing Stats
              <InfoTooltip text={TOOLTIPS.WRITING_STATS} />
            </Typography>

            <WritingStats text={writingText} writingStats={writingData.userAssignment.writingStats} />
          </Grid>
        </Grid>
      </Paper>
      <GwenStepContextProvider>
        <StepsAnalytics
          assignment={writingData.assignment}
          student={writingData.student}
          templateComponents={templateComponents}
          useOutline={useOutline}
        />
      </GwenStepContextProvider>
    </Container>
  );
}
