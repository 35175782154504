import { Container, Paper } from '@mui/material';
import { ClassForm } from '../components/ClassForm';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';

export function ClassNewPage() {
  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 2 }}>
        <ClassForm />
      </Paper>
    </Container>
  );
}
