import { useContext } from 'react';
import { Button, CircularProgress } from '@mui/material';
import useEdlinkIntegrationsSync from '../../../hooks/edlink/useIntegrationsSync';
import { UserContext } from '../../../contexts/UserContext';
import { IntegrationsList } from './IntegrationsList';

export const IntegrationsPage = () => {
  const user = useContext(UserContext);
  const { mutate: syncEdlinkIntegrations, isLoading, error, isError, isSuccess, data } = useEdlinkIntegrationsSync();

  // TODO: We get what got synced but it may happen that we'll also have in DB old, no longer active integrations.
  // Let's cover removing old integrations in the future and maybe after sync display data from the DB.

  return (
    user.isAdmin && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Button onClick={syncEdlinkIntegrations} disabled={isLoading} variant="contained">
          Sync Integrations Data
        </Button>
        {isSuccess && (
          <>
            <p>Sync completed</p>
            <IntegrationsList integrations={data.integrations} />
          </>
        )}
        {isError && <p style={{ color: 'red' }}>Error: {error.message}</p>}
        {isLoading && (
          <>
            <CircularProgress style={{ margin: '8px 0' }} />
            <span>Syncing...</span>
          </>
        )}
      </div>
    )
  );
};
