import { Container, Paper } from '@mui/material';
import { LoadingError } from '../../../components/LoadingError';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { useGoogleCoursesList } from '../api/useGoogleCoursesList';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';
import { GoogleLoginButton } from '../../home/GoogleLoginButton';
import { GOOGLE_SIGN_UP_URL } from '../../../config';
import { ClassForm } from '../components/ClassForm';

const BasePage = ({ children, queryState, isCustomError, customErrorFallback }) => {
  const { isLoading, isError, error } = queryState;
  let content;

  if (isLoading) {
    content = <LoadingIndicator />;
  } else if (isCustomError) {
    content = customErrorFallback;
  } else if (isError) {
    content = <LoadingError error={error} />;
  } else {
    content = children;
  }

  return <Container>
    <Breadcrumb />
    <Paper sx={{ padding: 2 }}>{content}</Paper>
  </Container>;
};

export const ClassImportPage = () => {
  const { data: coursesList, isLoading, isError, error } = useGoogleCoursesList();
  const grantTeacherPermissions = () => window.open(`${GOOGLE_SIGN_UP_URL}/teacher`, '_self');

  return (
    <BasePage
      queryState={{ isLoading, isError, error }}
      isCustomError={isError && error.response?.data?.googleSync}
      customErrorFallback={
        <GoogleLoginButton
          label="Grant teacher permissions to your Google Classroom"
          onClick={grantTeacherPermissions}
        />
      }
    >
      <ClassForm googleCourses={coursesList} />
    </BasePage>
  );
};
