export const ExternalSource = {
  GOOGLE: 'google',
  EDLINK: 'edlink',
  // cognito: 'cognito' - at the moment we don't need it
};

export const Grades = {
  FIRST: '01',
  SECOND: '02',
  THIRD: '03',
  FOURTH: '04',
  FIFTH: '05',
  SIXTH: '06',
  SEVENTH: '07',
  EIGHTH: '08',
  NINTH: '09',
  TENTH: '10',
  ELEVENTH: '11',
  TWELFTH: '12',
  POSTSECONDARY: 'PS',
  ADULT_LEARNING: 'AL',
  UNGRADED: 'UG',
};

export const UserAssignmentStatus = {
  CREATED: 'CREATED',
  PUBLISHED: 'PUBLISHED',
  STARTED: 'STARTED', // TODO: not fully implemented yet
  SUBMITTED: 'SUBMITTED',
};

// TODO: Use this enum
// export const UserRoles = {
//   STUDENT: 'student',
//   TEACHER: 'teacher',
//   ADMIN: 'admin',
// };
